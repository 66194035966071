// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-0-js": () => import("./../../../src/pages/blogs/0.js" /* webpackChunkName: "component---src-pages-blogs-0-js" */),
  "component---src-pages-blogs-1-js": () => import("./../../../src/pages/blogs/1.js" /* webpackChunkName: "component---src-pages-blogs-1-js" */),
  "component---src-pages-blogs-2-js": () => import("./../../../src/pages/blogs/2.js" /* webpackChunkName: "component---src-pages-blogs-2-js" */),
  "component---src-pages-blogs-3-js": () => import("./../../../src/pages/blogs/3.js" /* webpackChunkName: "component---src-pages-blogs-3-js" */),
  "component---src-pages-blogs-4-js": () => import("./../../../src/pages/blogs/4.js" /* webpackChunkName: "component---src-pages-blogs-4-js" */),
  "component---src-pages-blogs-5-js": () => import("./../../../src/pages/blogs/5.js" /* webpackChunkName: "component---src-pages-blogs-5-js" */),
  "component---src-pages-blogs-6-js": () => import("./../../../src/pages/blogs/6.js" /* webpackChunkName: "component---src-pages-blogs-6-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marchmadness-js": () => import("./../../../src/pages/marchmadness.js" /* webpackChunkName: "component---src-pages-marchmadness-js" */),
  "component---src-pages-mm-2022-js": () => import("./../../../src/pages/mm2022.js" /* webpackChunkName: "component---src-pages-mm-2022-js" */),
  "component---src-pages-mm-bracket-js": () => import("./../../../src/pages/mm/bracket.js" /* webpackChunkName: "component---src-pages-mm-bracket-js" */),
  "component---src-pages-mm-index-js": () => import("./../../../src/pages/mm/index.js" /* webpackChunkName: "component---src-pages-mm-index-js" */),
  "component---src-pages-mm-matchup-js": () => import("./../../../src/pages/mm/matchup.js" /* webpackChunkName: "component---src-pages-mm-matchup-js" */),
  "component---src-pages-mm-team-js": () => import("./../../../src/pages/mm/team.js" /* webpackChunkName: "component---src-pages-mm-team-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

